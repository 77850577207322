<template>
  <div class="footer-box" v-if="!isAlibabaDistributor">
    <!--多快好省-->
    <div class="footer_describe" v-if="footerLogo">
      <div class="footer_describe_content">
        <div class="footer_describe_item" v-for="(item, index) in describeList" :key="index">
          <h2 class="title" :class="languagesVal !=='CN' ? 'set_title' : ''">{{ item.title }}</h2>
          <div class="describe_conter">
            <h3 class="describe_title">{{ item.describeTitle }}</h3>
            <p class="describe_txt">{{ item.describeTxt }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--底部信息-->
    <div class="footer-info-box" :style="footerBackgroundColor">
      <div class="footer-info-content">
        <div class="footer-nav">
          <!--<div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">新手指南</p>
            <p class="nav-item-menu">入门指引</p>
            <p class="nav-item-menu">如何充值</p>
            <p class="nav-item-menu">如何订阅商品</p>
            <p class="nav-item-menu">如何刊登商品</p>
            <p class="nav-item-menu">如何下单发货</p>
          </div>-->
          <div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">{{ $t('key1000030') }}</p>
            <p class="nav-item-menu"><a :href="registerHref" target="_blank">{{ $t('key1000031') }}</a></p>
            <!--<p class="nav-item-menu">分销优势</p>-->
          </div>
          <div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">{{ $t('key1000032') }}</p>
            <p class="nav-item-menu">
              <a :href="supplierSettlementUrl" target="_blank">{{ $t('key1000033') }}</a>
            </p>
            <p class="nav-item-menu">
              <a :href="supplierEntryGuidelinesUrl" target="_blank">{{ $t('key1000034') }}</a>
            </p>
            <p class="nav-item-menu">
              <a :href="supplierLoginUrl" target="_blank">{{ $t('key1000035') }}</a>
            </p>

          </div>
          <div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">{{ $t('key1000036') }}</p>
            <p class="nav-item-menu"><a :href="helpUrl" target="_blank">{{ $t('key1000037') }}</a></p>
            <p class="nav-item-menu"><a :href="declarationUrl" target="_blank">{{ $t('key1000038') }}</a></p>
            <p class="nav-item-menu"><a :href="termsOfServiceUrl" target="_blank">{{ $t('key1000039') }}</a></p>
            <p class="nav-item-menu"><a :href="privacyPolicyUrl" target="_blank">{{ $t('key1000040') }}</a></p>
          </div>
          <div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">{{ $t('key1000041') }}</p>
            <p class="nav-item-menu"><a :href="introduceUrl" target="_blank">{{ $t('key1000042') }}</a></p>
            <p class="nav-item-menu"><a :href="businessIntroduceUrl" target="_blank">{{ $t('key1000043') }}</a></p>
            <p class="nav-item-menu"><a :href="contactUsUrl" target="_blank">{{ $t('key1000044') }}</a></p>
          </div>
          <div class="nav-item">
            <p class="nav-item-title" :style="navItemTitleStyle">{{ $t('key1000045') }}</p>
            <p class="phone" :style="navItemTitleStyle">400-0600-405</p>
            <p class="nav-item-menu">{{ $t('key1000046') }}</p>
            <p class="nav-item-menu">QQ：2853913746</p>
          </div>
        </div>
        <div class="keep-on-record">
          <span>{{ $t('key1000047') }}</span>
          <span>©2010-2021 yms.com. All Rights Reserved</span>
          <a href="https://beian.miit.gov.cn" target="_blank">{{ $t('key1000048') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import {getHrefPath, isAlibabaDistributor} from '@/utils/common';

export default {
  name: 'shopfooter',
  mixins: [Mixin],
  props: {
    footerLogo: { // 默认展示多快好省
      type: Boolean,
      default: true
    },
    footerTheme: { //  底部默认的颜色背景  dark 或 light
      type: String,
      default: 'dark'
    }
  },
  data() {
    return {
      describeList: [
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000049'), describeTitle: alias47916751af154eb5b47cd5de9d34633d.t('key1000050'), describeTxt: alias47916751af154eb5b47cd5de9d34633d.t('key1000051')},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000052'), describeTitle: alias47916751af154eb5b47cd5de9d34633d.t('key1000053'), describeTxt: alias47916751af154eb5b47cd5de9d34633d.t('key1000054')},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000055'), describeTitle: alias47916751af154eb5b47cd5de9d34633d.t('key1000056'), describeTxt: alias47916751af154eb5b47cd5de9d34633d.t('key1000057')},
        {title: alias47916751af154eb5b47cd5de9d34633d.t('key1000058'), describeTitle: alias47916751af154eb5b47cd5de9d34633d.t('key1000059'), describeTxt: alias47916751af154eb5b47cd5de9d34633d.t('key1000060')}
      ],
    }
  },
  methods: {},
  computed: {
    footerBackgroundColor() {
      let bgColor = {
        backgroundColor: this.footerTheme === 'dark' ? '#272B2E' : '#fff'
      }
      return bgColor;
    },
    navItemTitleStyle() {
      return {
        color: this.footerTheme === 'dark' ? '#fff' : '#000'
      }
    },
    // 免费注册
    registerHref() {
      let url = '/login.html#/register';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 常见问题
    helpUrl() {
      let url = '/index.html#/help';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 法律声明
    declarationUrl() {
      let url = '/index.html#/legalDeclaration';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 服务条款
    termsOfServiceUrl() {
      let url = '/index.html#/termsOfService';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 隐私政策
    privacyPolicyUrl() {
      let url = '/index.html#/privacyPolicy';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // YMS商城介绍
    introduceUrl() {
      let url = '/index.html#/introduce';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 平台业务
    businessIntroduceUrl() {
      let url = '/index.html#/businessIntroduce';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 联系我们
    contactUsUrl() {
      let url = '/index.html#/contactUs';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 供应商登录
    supplierLoginUrl() {
      let url = '/supplier.html#/statistics';
      let service = '/yms-supplier-service';
      return getHrefPath(url, service);
    },
    // 供应商入驻
    supplierSettlementUrl() {
      let url = '/login.html#/supplierSettlement';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    // 供应商入驻指南
    supplierEntryGuidelinesUrl() {
      let url = '/index.html#/supplierEntryGuidelines';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
    isAlibabaDistributor() {
      return isAlibabaDistributor()
    }
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.footer-box {
  width: 100%;
  background: #E7E8ED;

  .footer_describe {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;

    .footer_describe_content {
      width: 1200px;
      display: flex;
      height: 120px;
      justify-content: space-between;
      align-items: center;

      .footer_describe_item {
        display: flex;
        justify-content: center;
        flex: 1;
        height: 100%;
        align-items: center;

        .title {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          border: 2px solid #FB5532;
          color: #FB5532;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          font-family: 'PingFangSC-Regular';
        }

        .set_title {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid #fb5532;
          color: #fb5532;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 12px;
          font-family: 'PingFangSC-Regular';
        }

        .describe_conter {
          flex: 1;
          margin: 0 10px;

          .describe_title {
            color: #333333;
            font-size: 15px;
            font-weight: bold;
            font-family: 'PingFangSC-Semibold';
          }

          .describe_txt {
            color: #333333;
            font-size: 14px;
            font-family: 'PingFangSC-Regular';
            opacity: .6;
          }
        }
      }
    }
  }

  .footer-info-box {
    background-color: #272B2E;

    .footer-info-content {
      width: 1200px;
      margin: 0 auto;

      .footer-nav {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 40px;

        .nav-item {
          width: 160px;
          text-align: left;

          .nav-item-title {
            font-size: 16px;
            color: #FFFFFF;
            margin-bottom: 14px;
          }

          .nav-item-menu {
            padding: 2px 0;
            font-size: 14px;
            cursor: pointer;
            color: #a2a4a5;

            a {
              color: #a2a4a5;
            }

            a:hover {
              color: @subject_color;
            }
          }

          .phone {
            font-size: 20px;
            color: #FFFFFF;
            padding-bottom: 15px;
          }
        }
      }

      .keep-on-record {
        border-top: 1px solid #585A5B;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #A2A4A5;

        span {
          padding: 0 5px;
        }

        a {
          color: #A2A4A5;

          &:hover {
            color: #FD5425;
          }
        }
      }
    }
  }
}
</style>
